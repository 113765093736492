import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import useClient from '../util/useClient/useClient'
import warningSign from './assets/warningSign.svg'

function Error() {

  const client = useClient();

  useEffect(() => {
    document.title = `Error | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
  }, [client])

  return (
    <div className="body text-center">
      <Alert>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <img src={warningSign} alt="Warning sign" height="100px" className="mb-5 mt-4" />
        <p>
          Something went wrong but don't worry, we're already taking care of it.
        </p>
        <p>
          Please <a className='link' href={client === 'finl-web' ? 'https://www.finishline.com/store/' : 'https://www.jdsports.com/store/'}>click here to go back to the store</a>.
        </p>
        <p>
          If this has happened before please contact Customer Care at {client === 'finl-web' ? <strong>1-888-777-3949.</strong> : <strong>1-855-423-1624.</strong>}
        </p>
      </Alert>
    </div>
  )
}

export default Error
