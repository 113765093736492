import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ApiService } from '../../js/ApiService'
import useClient from '../util/useClient/useClient'
import Loading from '../util/Loading/Loading'
import RegisterReCaptcha from '../ReCaptcha/RegisterReCaptcha/RegisterReCaptcha'
import ErrorSummary from "../util/ErrorSummary/ErrorSummary"

import STATUSLogo from '../../styles/themes/status/assets/STATUS_logo_black.svg'
import JDFNLBrand from '../../styles/themes/status/assets/JD_FNL_Brand.svg'
import { emailValidation, passWordValidation } from '../../js/utils'

function FinishCompleteRegistration({ token }) {

  const { register, errors, handleSubmit, setValue, formState: { isSubmitted, isSubmitting, isValid } } = useForm({ mode: 'onChange' });
  const client = useClient();
  const hiddenForm = useRef(null);
  const [validatingToken, setValidatingToken] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [reCaptchaToken, setreCaptchaToken] = useState(null);
  const [readOnlyInputs, setReadOnlyInputs] = useState({
    iusername: false,
    firstName: false,
    lastName: false
  });
  const [password, setPassword] = useState('');
  const [passFocused, setpassFocused] = useState(false);


  useEffect(() => {
    document.title = `Create Account | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
    async function loadUserAndSetup() {
      setValidatingToken(true);
      ApiService.validateOTP(token).then((res) => {
        setValidatingToken(false);
        if (res.status === 200) {
          setValidToken(true);
          return res.json();
        }
        if (res.status === 400 || res.status === 404) {
          return res.json().then((d) => setErrMsg(d.error));
        }
        return res.text().then((msg) => setErrMsg(msg));
      })
        .then((userInfo) => {
          if (userInfo) {
            if (!!userInfo.username && userInfo.username.trim() !== '') {
              setValue('iusername', userInfo.username.toLowerCase(), { shouldValidate: true });
              setReadOnlyInputs(d => ({ ...d, iusername: true }));
            }
            if (!!userInfo.firstName && userInfo.firstName.trim() !== '') {
              setValue('firstName', userInfo.firstName, { shouldValidate: true });
              setReadOnlyInputs(d => ({ ...d, firstName: true }));
            }
            return userInfo;
          }
        })
    }
    loadUserAndSetup();
  }, [setValue, token, client])
  

  const onSubmit = data => {
    if (!!!reCaptchaToken) {
      setErrMsg("You need to complete reCaptcha");
      return;
    }
    let structuredData = {
      username: data.iusername,
      password: data.ipassword,
      recaptchaToken: reCaptchaToken,
      token: token,
      userInfo: {
        clientId: client
      }
    }
    ApiService.completeRegistrationAcc(structuredData).then((res) => {
      if (res.status === 200) {
        setValue('username', data.iusername);
        setValue('password', data.ipassword);
        setValue('recaptchaToken', reCaptchaToken);
        if (hiddenForm && hiddenForm.current) return hiddenForm.current.submit();
      }
      if (res.status === 400 || res.status === 404) {
        return res.json().then((d) => setErrMsg(d.error));
      }
      return res.text().then((msg) => setErrMsg(msg));
    });
  };

  const handlePassChange = (e) => {
    setPassword(e.target.value);
  }

  const showErrorSummary = () => {
    setpassFocused(true);
  }

  const hideErrorSummary = () => {
    setpassFocused(false);
  }

  const handleToken = (ev) => {
    setreCaptchaToken(ev.token);
    setErrMsg(null);
  }

  //this one uses events because this may expire if the user takes forever
  document.addEventListener('rTokenRdy', handleToken, false);

  return (
    <div className="body bg-white" style={{ height: '100%' }} data-testid="finish-complete-reg">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="mt-4"><img src={STATUSLogo} alt="STATUS logo" /></div>
        <div className="mt-3">
          <span className="font-weight-bold line-height-1">
            ONE ACCOUNT.<br />MORE ACCESS.
          </span>
        </div>
        <div className="mt-1"><img className="family-logo" src={JDFNLBrand} alt="JD FNL Brand logo" /></div>
        <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>COMPLETE STATUS ACCOUNT REGISTRATION</h1>
        <div className="text-center mb-3" style={{ lineHeight: '20px' }}>
          <strong>Earn 10 points for every $1 you spend.</strong><br />
          <span>Get Points. Gain Access. Boost your STATUS.</span>
        </div>
      </div>
      {validatingToken &&
        <div className="d-flex align-items-center justify-content-center mb-3">
          <span>Validating Token...</span> <Loading inline="true" />
          {errMsg !== '' &&
            <div className="text-danger text-center pb-3">{errMsg}</div>
          }
        </div>
      }
      {(!validatingToken && !validToken) &&
        <div className="text-danger text-center pb-3">{errMsg}</div>
      }
      {(!validatingToken && validToken) &&
        <div className="form">
          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="mb-3">
              <input name="firstName" className="form-input" type="text"
                placeholder="First Name"
                aria-describedby="First name"
                aria-labelledby="firstName"
                readOnly={readOnlyInputs.firstName}
                ref={register({ required: { value: true, message: "Your first name is required" } })} />
              {errors.firstName &&
                <div className="form-text text-danger" role="alert" >
                  {errors.firstName.message}
                </div>
              }
            </div>

            <div className="mb-3">
              <input name="iusername" className="form-input" type="email"
                placeholder="Email"
                aria-describedby="Email"
                aria-labelledby="iusername"
                readOnly={readOnlyInputs.iusername}
                onChange={e => e.target.value = e.target.value.toLowerCase()}
                ref={register({ validate: emailValidation })} />
              {errors.iusername &&
                <div className="form-text text-danger" role="alert" >
                  {errors.iusername.message}
                </div>
              }
            </div>

            <div className="mb-3">
              <input name="ipassword" type="password" className="form-input"
                placeholder="Password"
                aria-describedby="Password"
                aria-labelledby="ipassword"
                ref={register({ validate: passWordValidation })}
                onChange={handlePassChange}
                onFocus={showErrorSummary}
                onBlur={hideErrorSummary} />
              {errors.ipassword &&
                <div className="form-text text-danger" role="alert" >
                  {errors.ipassword.message}
                </div>
              }
            </div>

            <ErrorSummary value={password} show={passFocused} />

            <div className="mb-3">
              <RegisterReCaptcha action="REGISTER" />
            </div>

            <div className="text-center my-3">
              <input disabled={isSubmitted || isSubmitting || !isValid} type="submit" className="btn btn-primary btn-block" value="Create Account" />
            </div>
            <div className="text-center pb-4" style={{ width: '343px', margin: '0 auto' }}>
    {client === 'finl-web' &&
        <div>
            <span className="small-font">By creating an account, you enroll in the STATUS Program subject to our&nbsp;
                <a className="link" href="https://www.finishline.com/status_terms">STATUS Terms &amp; Conditions</a>,&nbsp;
                <a className="link" href="https://www.finishline.com/store/corporate/privacyPolicy.jsp">Privacy Policy</a>, and&nbsp;
                <a className="link" href="https://www.finishline.com/store/corporate/terms.jsp">Terms of Use</a> and certify that you are 18 years old and older. Ages 13 - 17 must enroll with consent from a parent or guardian who has agreed to be bound by these Terms on your behalf. Children under 13 may not enroll.
            </span>
        </div>
    }
    {client === 'jdsp-web' &&
        <div>
            <span className="small-font">By creating an account, you enroll in the STATUS Program subject to our&nbsp;
                <a className="link" href="https://www.jdsports.com/status_terms">STATUS Terms &amp; Conditions</a>,&nbsp;
                <a className="link" href="https://www.jdsports.com/store/corporate/privacyPolicy.jsp">Privacy Policy</a>, and&nbsp;
                <a className="link" href="https://www.jdsports.com/store/corporate/terms.jsp">Terms of Use</a> and certify that you are 18 years old and older. Ages 13 - 17 must enroll with consent from a parent or guardian who has agreed to be bound by these Terms on your behalf. Children under 13 may not enroll.
            </span>
        </div>
    }
            </div>
            {errMsg !== '' &&
              <div className="text-danger text-center pb-3">{errMsg}</div>
            }
          </form>
        </div>

      }
      <form ref={hiddenForm} id="hiddenLoginForm" className="invisible" method="POST" action="/account/login">
        <input id="username" name="username" ref={register} type="text" />
        <input id="password" name="password" ref={register} type="password" />
        <input id="recaptchaToken" name="recaptchaToken" ref={register} type="recaptchaToken" />
      </form>
    </div>
  )
}

export default FinishCompleteRegistration
