import Cookies from "js-cookie";
import { localExecutionClient } from "../../../js/localExecutionClient";

function useClient() {
  let client = "";

  if (window.location) {
    const url = window.location;
    const clientId = Cookies.get("FNL_RISKIFIED_CLIENT");
    if (clientId) {
      client = clientId;
    } else {
      if (url?.host?.includes("finishline")) client = "finl-web";
      if (url?.host?.includes("jdsports")) client = "jdsp-web";
      if (url?.host?.includes("localhost")) client = localExecutionClient;
    }
  }

  return client;
}

export default useClient;
