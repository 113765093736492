import React, { useEffect, useState } from 'react'
import { forOwn, clone } from 'lodash'
import { passWordValidation } from '../../../js/utils';

function ErrorSummary({value, show}) {

  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    const EMclone = clone(errorMessages);
    forOwn(passWordValidation, (f, k)=>{
      if(k !== 'required'){
        EMclone[k] = f(value);
        setErrorMessages(EMclone);
      }
    })
  }, [value])

  return show ? (
    <div className="mb-3">
      <span className="form-text">Your password must contain:</span>
      <div className={errorMessages.lengthCheck ? 'mx-1 form-text text-success' : 'mx-1 form-text text-danger'} role="alert" >
        {errorMessages.lengthCheck ? <span>&#x2713;</span> : <span>&#x2715;</span> } 10-25 characters
      </div>
      <div className={errorMessages.digitCheck ? 'mx-1 form-text text-success' : 'mx-1 form-text text-danger'} role="alert" >
        {errorMessages.digitCheck ? <span>&#x2713;</span> : <span>&#x2715;</span> } At least 1 number
      </div>
      <div className={errorMessages.upperCaseCheck ? 'mx-1 form-text text-success' : 'mx-1 form-text text-danger'} role="alert" >
        {errorMessages.upperCaseCheck ? <span>&#x2713;</span> : <span>&#x2715;</span> } At least 1 uppercase letter
      </div>
      <div className={errorMessages.lowerCaseCheck ? 'mx-1 form-text text-success' : 'mx-1 form-text text-danger'} role="alert" >
        {errorMessages.lowerCaseCheck ? <span>&#x2713;</span> : <span>&#x2715;</span> } At least 1 lowercase letter
      </div>
      <div className={errorMessages.specialCharCheck ? 'mx-1 form-text text-success' : 'mx-1 form-text text-danger'} role="alert" >
        {errorMessages.specialCharCheck ? <span>&#x2713;</span> : <span>&#x2715;</span> } At least 1 special character
      </div>
    </div>
  ) : null
}

export default ErrorSummary
