import moment from 'moment';

const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const upperCasePattern = /(?=.*[A-Z])/;
const lowerCasePattern = /(?=.*[a-z])/;
const specialCharPattern = /(?=.*[!"#$%'&()*+,-./:;<=>?@^_`{|}~])/;
const digitPattern = /(?=.*[0-9])/;

export const emailValidation = {
  required: value => (value && value.trim() !== '')  || 'Email is required.',
  valid: value => (value && emailPattern.test(value)) || 'You must use a valid email.'
}

export const passWordValidation = {
  required: value => (value && value.trim() !== '') || 'Password is required.',
  upperCaseCheck: value => (value && upperCasePattern.test(value)),
  lowerCaseCheck: value => (value && lowerCasePattern.test(value)),
  specialCharCheck: value => (value && specialCharPattern.test(value)),
  digitCheck: value => (value && digitPattern.test(value)),
  lengthCheck: value => (value && value.length >= 10 && value.length <= 25),
}

export const dobValidation = {
  valid: value => !value || moment(value).isValid() || 'Please enter a valid date.',
  adult: value => !value || (moment(value).isSameOrBefore(moment().subtract(13, "years"))) || 'You must be 13+ years old to register.',
}
