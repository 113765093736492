import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { passWordValidation } from '../../js/utils'
import { ApiService } from '../../js/ApiService'
import Loading from '../util/Loading/Loading'
import CheckBBG from './assets/check-blackbg.svg'
import useClient from '../util/useClient/useClient';
import useEnvSite from '../util/useEnvSite/useEnvSite';
import ErrorSummary from "../util/ErrorSummary/ErrorSummary"

function FinishResetPassword() {

  const { register, errors, handleSubmit, getValues, formState:{ isSubmitted, isSubmitting, isValid }} = useForm({
    mode: 'onChange',
  });
  const urlParams = new URLSearchParams(useLocation().search);
  const emailToken = urlParams.get('emailToken');
  const deeplink = urlParams.get('url');
  const [isLoading, setIsLoading] = useState(!!emailToken);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const client = useClient();
  const envSite = useEnvSite();
  const [password, setPassword] = useState('');
  const [passFocused, setpassFocused] = useState(false);

  useEffect(() => {
    document.title = `Reset Password | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
    if (emailToken && isLoading) {
      ApiService.validateResetToken({ emailToken })
        .then(res => {
          setIsLoading(false);
          if (res.status === 200) {
            setIsTokenValid(true);
          } else {
            setIsTokenValid(false);
          }
        })
    }
  }, [client, emailToken, isLoading])

  const onSubmit = ({ newPassword }) => {
    ApiService.finishResetPassword({ emailToken, newPassword })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          var link = (!!deeplink && deeplink !== "null") ? deeplink : `https://${envSite.env}.${envSite.site}.com/store/myaccount/login.jsp`;
          return setTimeout(() => {
            window.location.href = link;
          }, 5000)
        }
        res.text().then((msg) => setErrMsg(msg));
      })
  };

  const handlePassChange = (e) => {
    setPassword(e.target.value);
  }

  const showErrorSummary = () => {
    setpassFocused(true);
  }

  const hideErrorSummary = () => {
    setpassFocused(false);
  }

  return (
    <div className="body bg-white">
      <div>
        {!success && emailToken &&
          <div>
            <div className="d-flex flex-column align-items-center justify-content-center banner">
              <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>RESET PASSWORD</h1>
            </div>
            {isLoading &&
              <div className="d-flex align-items-center justify-content-center mb-3" style={{ height: '60%' }}>
                <span>Validating Token...</span> <Loading inline="true" />
              </div>
            }
            {!isLoading &&
              <div>
                {!isTokenValid &&
                  <div>
                    <p className="text-center py-5">The token provided already expired or is invalid</p>
                  </div>
                }
                {isTokenValid &&
                  <div className="form">
                    <form onSubmit={handleSubmit(onSubmit)}>

                      <div className="mb-3 text-left">
                        <input name="newPassword" type="password" className="form-input"
                          maxLength="25"
                          placeholder="New Password"
                          aria-describedby="Email"
                          aria-labelledby="newPassword"
                          ref={register({ validate: passWordValidation })}
                          onChange={handlePassChange}
                          onFocus={showErrorSummary}
                          onBlur={hideErrorSummary} />
                        {errors.newPassword &&
                          <div className="form-text text-danger" role="alert" >
                            {errors.newPassword.message}
                          </div>
                        }
                      </div>

                      <ErrorSummary value={password} show={passFocused} />

                      <div className="mb-3 text-left">
                        <input name="confirmPassword" type="password" className="form-input"
                          maxLength="25"
                          placeholder="Confirm New Password"
                          aria-describedby="Email"
                          aria-labelledby="confirmPassword"
                          ref={register({ required: { value: true, message: "You must confirm your password." }, validate: { equalsNewPassword: value => value === getValues('newPassword') || "You must enter the same password as above." } })} />
                        {errors.confirmPassword &&
                          <div className="form-text text-danger" role="alert" >
                            {errors.confirmPassword.message}
                          </div>
                        }
                      </div>

                      <div className="text-center my-4">
                        <input disabled={isSubmitted || isSubmitting || !isValid} type="submit" className="btn btn-primary btn-block" value="Submit" />
                      </div>
                    </form>
                    {errMsg !== '' &&
                      <div className="text-danger text-center">{errMsg}</div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
        {!success && !emailToken &&
          <div>
            <p className="text-center py-5">You need a token to reset your password</p>
          </div>
        }
        {success &&
          <div>
            <div className="d-flex flex-column align-items-center justify-content-center banner">
              <div className="mt-4"><img src={CheckBBG} alt="Success Check" /></div>
              <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>RESET PASSWORD</h1>
              <div className="text-left mb-4" style={{ lineHeight: '20px' }}>
                <span>Your password has been reset. You will now be redirected to the STATUS account login page. If you are not automatically redirected, please&nbsp;
                  <a href={!!deeplink ? deeplink : `https://${envSite.env}.${envSite.site}.com/store/myaccount/login.jsp`} className="link">click here</a>.
                </span>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default FinishResetPassword
