import React, { useEffect, useState } from "react";
import { ApiService } from "../../../js/ApiService";
import Loading from "../Loading/Loading";
import useEnvSite from "../useEnvSite/useEnvSite";

function ValidateUser({ children }) {
  const [validated, setValidated] = useState(false);
  const { env, site } = useEnvSite();
  const redirectUrl = `https://${env}.${site}.com/store/`;

  useEffect(() => {
    async function validateUser() {
      if (!validated) {
        await ApiService.validateUser()
          .then((res) => {
            if (res.status === 200) {
              setValidated(true);
            } else {
              window.location = redirectUrl;
            }
          })
          .catch(() => (window.location = redirectUrl));
      }
    }

    validateUser();
  });

  return (
    <>
      {validated && <>{children}</>}
      {!validated && (
        <div
          className="d-flex align-items-center justify-content-center mb-3"
          style={{ height: "60%" }}
        >
          <span>Validating Session...</span> <Loading inline="true" />
        </div>
      )}
    </>
  );
}

export default ValidateUser;
