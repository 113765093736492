import {
  localExecutionEnv,
  localExecutionSite,
} from "../../../js/localExecutionClient";

function useEnvSite() {
  let envSite = {
    env: localExecutionEnv,
    site: localExecutionSite,
    redirectURI: null,
  };

  if (window.location) {
    const { host, search } = window.location;

    const urlParams = new URLSearchParams(search);
    if (urlParams.get("redirect_uri"))
      envSite.redirectURI = decodeURIComponent(urlParams.get("redirect_uri"));
    if (host.includes("finishline")) envSite.site = "finishline";
    if (host.includes("jdsports")) envSite.site = "jdsports";

    const env = host.split(".")[0];
    const environments = [
      "localhost",
      "local",
      "dev",
      "qa",
      "stage",
      "uat",
      "prod",
    ];
    environments.forEach((environment) => {
      if (env.includes(environment)) envSite.env = env;
    });
  }
  return envSite;
}

export default useEnvSite;
