import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { ApiService } from '../../js/ApiService'
import useClient from '../util/useClient/useClient'
import RegisterReCaptcha from '../ReCaptcha/RegisterReCaptcha/RegisterReCaptcha'
import ErrorSummary from "../util/ErrorSummary/ErrorSummary"
import MaskedInput from "react-maskedinput"
import moment from 'moment'
import getRISKX from '../util/Riskified/getRISKX'

import STATUSLogo from '../../styles/themes/status/assets/STATUS_logo_black.svg'
import JDFNLBrand from '../../styles/themes/status/assets/JD_FNL_Brand.svg'
import { passWordValidation, dobValidation, emailValidation } from '../../js/utils'

function Register() {

  const { register, errors, handleSubmit, setValue, control, formState: { isSubmitted, isSubmitting, isValid } } = useForm({ 
    mode: 'onChange',
  }); 
  const client = useClient();
  const search = useLocation().search;
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const hiddenForm = useRef(null);
  const [errMsg, setErrMsg] = useState('');
  const [reCaptchaToken, setreCaptchaToken] = useState(null);
  const [readOnlyInputs, setReadOnlyInputs] = useState({
    iusername: false,
    firstName: false,
    lastName: false
  });
  const [password, setPassword] = useState('');
  const [passFocused, setpassFocused] = useState(false);

  useEffect(() => {
    document.title = `Create Account | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
    const params = {
      iusername: urlParams.get('username'),
      firstName: urlParams.get('firstName'),
      lastName: urlParams.get('lastName'),
    }

    if (!!params.iusername && params.iusername.trim() !== '') {
      setValue('iusername', params.iusername, { shouldValidate: true });
      setReadOnlyInputs(d => ({ ...d, iusername: true }));
    }
    if (!!params.firstName && params.firstName.trim() !== '') {
      setValue('firstName', params.firstName, { shouldValidate: true });
      setReadOnlyInputs(d => ({ ...d, firstName: true }));
    }
    if (!!params.lastName && params.lastName.trim() !== '') {
      setValue('lastName', params.lastName, { shouldValidate: true });
      setReadOnlyInputs(d => ({ ...d, lastName: true }));
    }

    async function handleRISKX() {
      const RISKX = await getRISKX();
      RISKX.go('/register');
    }

    handleRISKX();
  }, [setValue, urlParams, client])

  const onSubmit = data => {
    if (!reCaptchaToken) {
      setErrMsg("You need to complete reCaptcha");
      return;
    }
    const isValidDate = moment(data.dateOfBirth).isValid();
    if (isValidDate) {
      data.dateOfBirth = moment(data.dateOfBirth).format('YYYY-MM-DD');
    }
    let structuredData = {
      username: data.iusername,
      password: data.ipassword,
      recaptchaToken: reCaptchaToken,
      userInfo: {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: isValidDate ? data.dateOfBirth : null,
        clientId: client
      }
    }
    ApiService.addUser(structuredData).then((res) => {
      if (res.status === 200) {
        setValue('username', data.iusername);
        setValue('password', data.ipassword);
        setValue('recaptchaToken', reCaptchaToken);
        if (hiddenForm && hiddenForm.current) hiddenForm.current.submit();
      }
      res.text().then((msg) => setErrMsg(msg));
    });
  };

  const handlePassChange = (e) => {
    setPassword(e.target.value);
  }

  const showErrorSummary = () => {
    setpassFocused(true);
  }

  const hideErrorSummary = () => {
    setpassFocused(false);
  }

  const handleToken = (ev) => {
    setreCaptchaToken(ev.token);
    setErrMsg(null);
  }

  //this one uses events because this may expire if the user takes forever
  document.addEventListener('rTokenRdy', handleToken, false);

  return (
    <div className="body">
      <div className="bg-white">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mt-4"><img src={STATUSLogo} alt="STATUS logo" /></div>
          <div className="mt-3">
            <span className="font-weight-bold line-height-1">
              ONE ACCOUNT.<br />MORE ACCESS.
            </span>
          </div>
          <div className="mt-1"><img className="family-logo" src={JDFNLBrand} alt="JD FNL Brand logo" /></div>
          <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>CREATE A STATUS ACCOUNT</h1>
          <div className="text-center mb-3" style={{ lineHeight: '20px' }}>
            <strong>Earn 10 points for every $1 you spend.</strong><br />
            <span>Get Points. Gain Access. Boost your STATUS.</span>
          </div>
        </div>
        <div className="form">
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="mb-3">
              <input name="firstName" className="form-input" type="text"
                placeholder="First Name"
                aria-label='First name'
                readOnly={readOnlyInputs.firstName}
                ref={register({ required: { value: true, message: "Your first name is required" } })} />
              {errors.firstName &&
                <div className="form-text text-danger" role="alert" >
                  {errors.firstName.message}
                </div>
              }
            </div>

            <div className="mb-3">
              <input name="lastName" className="form-input" type="text"
                placeholder="Last Name"
                aria-label='Last name'
                readOnly={readOnlyInputs.lastName}
                ref={register({ required: { value: true, message: "Your last name is required" } })} />
              {errors.lastName &&
                <div className="form-text text-danger" role="alert" >
                  {errors.lastName.message}
                </div>
              }
            </div>

            <div className="mb-3">
              <Controller
                control={control}
                name="dateOfBirth"
                rules={{ validate: dobValidation }}
                defaultValue={null}
                render={({ value, onChange, onBlur }) => (
                  <MaskedInput className="form-input" type="tel" mask="19/39/2999" formatCharacters={{
                    '1': { validate: char => /[0-1]/.test(char) },
                    '2': { validate: char => /[0-2]/.test(char) },
                    '3': { validate: char => /[0-3]/.test(char) },
                    '9': { validate: char => /[0-9]/.test(char) }
                  }} 
                  placeholder="Birth Date" 
                  value={value} aria-label='Date of Birth' 
                  onChange={(e) => { onChange(e) }}
                  onBlur={onBlur}
                   />
                )}
              />
              {errors.dateOfBirth &&
                <div className="form-text text-danger" role="alert" >
                  {errors.dateOfBirth.message}
                </div>
              }
            </div>

            <div className="mb-3">
              <input name="iusername" className="form-input" type="email"
                placeholder="Email"
                aria-label='Email'
                readOnly={readOnlyInputs.iusername}
                ref={register({ validate: emailValidation })} />
              {errors.iusername &&
                <div className="form-text text-danger" role="alert" >
                  {errors.iusername.message}
                </div>
              }
            </div>

            <div className="mb-3">
              <input name="ipassword" type="password" className="form-input"
                maxLength="25"
                placeholder="Password"
                aria-label='Password'
                ref={register({ validate: passWordValidation })}
                onChange={handlePassChange}
                onFocus={showErrorSummary}
                onBlur={hideErrorSummary} />
              {errors.ipassword &&
                <div className="form-text text-danger" role="alert" >
                  {errors.ipassword.message}
                </div>
              }
            </div>

            <ErrorSummary value={password} show={passFocused} />

            <div className="mb-3">
              <RegisterReCaptcha action="CREATE_ACCOUNT" />
            </div>

            <div className="text-center my-3">
              <input disabled={isSubmitted || isSubmitting || !isValid} type="submit" className="btn btn-primary btn-block" value="Create Account" />
            </div>
          </form>
          <div className="text-center pb-4" style={{ width: '343px', margin: '0 auto' }}>
    {client === 'finl-web' &&
        <div>
            <span className="small-font">By creating an account, you enroll in the STATUS Program subject to our&nbsp;
                <a className="link" href="https://www.finishline.com/status_terms">STATUS Terms &amp; Conditions</a>,&nbsp;
                <a className="link" href="https://www.finishline.com/store/corporate/privacyPolicy.jsp">Privacy Policy</a>, and&nbsp;
                <a className="link" href="https://www.finishline.com/store/corporate/terms.jsp">Terms of Use</a> and certify that you are 18 years old and older. Ages 13 - 17 must enroll with consent from a parent or guardian who has agreed to be bound by these Terms on your behalf. Children under 13 may not enroll.
            </span>
        </div>
    }
    {client === 'jdsp-web' &&
        <div>
            <span className="small-font">By creating an account, you enroll in the STATUS Program subject to our&nbsp;
                <a className="link" href="https://www.jdsports.com/status_terms">STATUS Terms &amp; Conditions</a>,&nbsp;
                <a className="link" href="https://www.jdsports.com/store/corporate/privacyPolicy.jsp">Privacy Policy</a>, and&nbsp;
                <a className="link" href="https://www.jdsports.com/store/corporate/terms.jsp">Terms of Use</a> and certify that you are 18 years old and older. Ages 13 - 17 must enroll with consent from a parent or guardian who has agreed to be bound by these Terms on your behalf. Children under 13 may not enroll.
            </span>
        </div>
    }
          </div>
          {errMsg !== '' &&
            <div className="text-danger text-center pb-3">{errMsg}</div>
          }
        </div>
      </div>
      <hr className="fl-hr"></hr>
      <div className="formFooter text-center" style={{ top: "683px" }}>
        <p>Sign up for STATUS in a store?</p>
        <Link to='/completeRegistration'>
          <button className="btn btn-secondary btn-block" style={{ marginBottom: '32px' }}>
            COMPLETE YOUR REGISTRATION
          </button>
        </Link>
        <p>Already have a STATUS account?</p>
        <Link to='/login'>
          <button className="btn btn-secondary btn-block">
            SIGN IN
          </button>
        </Link>
      </div>
      <form ref={hiddenForm} id="hiddenLoginForm" className="invisible" method="POST" action="/account/login">
        <input id="username" name="username" ref={register} type="text" />
        <input id="password" name="password" ref={register} type="password" />
        <input id="recaptchaToken" name="recaptchaToken" ref={register} type="recaptchaToken" />
      </form>
    </div>
  )
}

export default Register
