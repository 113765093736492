function useSiteKey(flow) {
  var siteKey = "";

  if (window.location) {
    const url = window.location;
    switch (flow) {
      case 1: //login
        if (url?.host?.includes("finishline"))
          siteKey = "6LcPD74ZAAAAAFtuJvnpIV5VjKE16Ma7oRCcENIN";
        if (url?.host?.includes("jdsports"))
          siteKey = "6LdpAb4ZAAAAAKTaBdqCW12WwD3BWp63gKQtNMcC";
        break;
      case 2: //register
        if (url?.host?.includes("finishline"))
          siteKey = "6Lf4RNIZAAAAAAigQYYFSFKmjryLMByZ58MLH1sx";
        if (url?.host?.includes("jdsports"))
          siteKey = "6LfFxtIZAAAAAAdXivEaWUugriLPlcVOGAgIYZU2";
        break;
      default:
        // using login as default
        if (url?.host?.includes("finishline"))
          siteKey = "6LcPD74ZAAAAAFtuJvnpIV5VjKE16Ma7oRCcENIN";
        if (url?.host?.includes("jdsports"))
          siteKey = "6LdpAb4ZAAAAAKTaBdqCW12WwD3BWp63gKQtNMcC";
        break;
    }
  }

  return siteKey;
}

export default useSiteKey;
