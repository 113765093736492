import { useEffect } from 'react'
import useSiteKey from '../../util/useSiteKey/useSiteKey'

function RegisterReCaptcha({action}) {

  const siteKey = useSiteKey(2);

  useEffect(() => {
    window.localStorage.removeItem('token');
    const script = document.createElement('script');
    const script2 = document.createElement('script');

    script.src = "https://www.google.com/recaptcha/enterprise.js?onload=onloadCallback&render=explicit";
    script.async = false;

    script2.type = "text/javascript";
    script2.async = false;

    const script2Code =
    "var grecaptcha = null;" +
    "var cbRecaptchaSubmit = function(token) {" +
      "var evt = document.createEvent('Event');" +
      "evt.initEvent('rTokenRdy',true,true);" +
      "evt.token = token;" +
      "document.dispatchEvent(evt);" +
    "};" +
    "var onloadCallback = function(){" +
      "grecaptcha.enterprise.render('RegisterReCaptcha', {" +
        "'sitekey' : '" + siteKey + "'," +
        "'action': '" + action + "'," +
        "'callback' : cbRecaptchaSubmit" +
      "})" +
    "};"

    document.body.appendChild(script);
    script2.appendChild(document.createTextNode(script2Code));
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    }
  }, [])

  return (
    <div id="RegisterReCaptcha" data-testid="recaptcha"></div>
  )
}

export default RegisterReCaptcha
