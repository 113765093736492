import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ApiService } from '../../js/ApiService';
import useClient from '../util/useClient/useClient'

import CheckWBG from './assets/check-whitebg.svg';
import { emailValidation } from '../../js/utils'

function ResetPasswordPrompt() {

  const { register, errors, handleSubmit, formState:{ isSubmitted, isSubmitting, isValid }} = useForm({mode: 'onChange'});
  const client = useClient();
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.title = `Reset Password | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
  }, [client])

  const onSubmit = data => {
    const payload = {
      email: data.username,
      clientId: client
    }
    ApiService.requestResetPassword(payload).then(res => {
      if (res.status === 200) {
        setIsSuccess(true);
      }
      res.text().then((msg) => setMessage(msg));
    })
  };

  return (
    <div className="body bg-white">
      {!isSuccess &&
        <div>
          <div className="d-flex flex-column align-items-center justify-content-center banner">
            <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>RESET PASSWORD</h1>
            <div className="text-left mb-4" style={{ lineHeight: '20px' }}>
              <span>Enter your email address below and we'll send you a link to reset your password.</span>
            </div>
          </div>
          <div className="form">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="mb-3 text-left">
                <input name="username" className="form-input" type="email"
                  placeholder="Email"
                  aria-describedby="Email input"
                  aria-labelledby="username"
                  ref={register({ validate: emailValidation })} />
                {errors.username &&
                  <div className="form-text text-danger" role="alert" >
                    {errors.username.message}
                  </div>
                }
              </div>

              <div className="text-center my-4">
                <input disabled={isSubmitted || isSubmitting || !isValid} type="submit" className="btn btn-primary btn-block" value="CONTINUE" />
              </div>
            </form>
            {message !== '' &&
              <div className="text-danger text-center">{message}</div>
            }
          </div>
        </div>
      }
      {isSuccess &&
        <div>
          <div className="d-flex flex-column align-items-center justify-content-center banner">
            <div className="mt-4"><img src={CheckWBG} alt="Success Check" /></div>
            <h1 className="h1 mb-4 text-center" style={{ marginTop: '28px' }}>RESET PASSWORD</h1>
            <div className="text-left mb-4" style={{ lineHeight: '20px' }}>
              <span>If this email is in our system, we'll send you an email with a link to reset your password.</span>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ResetPasswordPrompt
