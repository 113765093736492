import React, { useEffect } from 'react'
import useClient from '../util/useClient/useClient';
import { Image } from 'react-bootstrap';

function ForceResetPassword() {

  const client = useClient();
  const imgSrc = client.startsWith('finl') ? 'https://media.finishline.com/i/finishline/FNL-lock-empty' : 'https://media.jdsports.com/i/jdsports/icon-lock-filled-jd'

  useEffect(() => {
    document.title = `Reset Password | ${client.startsWith('finl') ? 'Finish Line' : 'JD Sports'}`;
  }, [client])

  return (
    <div className="body bg-white text-center" style={{ padding: '75px 0' }}>
      <h1 className="h1 passResetBox">CHECK YOUR EMAIL TO RESET YOUR PASSWORD</h1>
      <Image src={imgSrc} alt="Lock icon" className="my-4" />
      <div className="passResetBox text-justify">
        <p>
          Your JD Sports and Finish Line accounts have been merged into one STATUS account, and you can now manage all of your information in one place. Sign in for faster checkout when new drops hit and to view your rewards.
        </p>
        <p>
          To keep your information secure, you'll need to reset your password to access your account.
        </p>
        <p>
          <strong>Check your email for a reset link.</strong> Come back to reset your password and sign in.
        </p>
      </div>
    </div>
  )
}

export default ForceResetPassword
