import React, { useState, useEffect } from 'react'
import useClient from '../../components/util/useClient/useClient';

import fnlLogo from './fnl-logo.svg'
import jdLogo from './jd-logo.svg'

function Header() {

  const client = useClient();
  const [headerStyles, setHeaderStyles] = useState(null);
  const [logo, setLogo] = useState(null);
  const [siteURL, setSiteURL] = useState(null);

  useEffect(() => {
    if (client.startsWith('finl')) {
      setHeaderStyles('fl-header');
      setLogo(fnlLogo);
      setSiteURL('https://www.finishline.com/');
    }
    if (client.startsWith('jdsp')) {
      setHeaderStyles('jd-header');
      setLogo(jdLogo);
      setSiteURL('https://www.jdsports.com/');
    }
  }, [client])

  return (
    <div>
      <div className={headerStyles + ' d-flex flex-row align-items-center justify-content-center'}>
        <a href={siteURL}>
          <img src={logo} alt="Site logo" />
        </a>
      </div>
      {client === 'finl-web' &&
        <hr className="fl-hr"></hr>
      }
    </div>
  )
}

export default Header
