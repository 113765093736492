import React, { useEffect } from "react";
import Router from "./router/Router";

import ThemeSelector from "./styles/themeSelector/ThemeSelector";
import Header from "./components/Header/Header";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import useClient from "./components/util/useClient/useClient";

import Riskified from "./components/util/Riskified/Riskified";
import { Helmet } from "react-helmet";
import useEnvSite from "./components/util/useEnvSite/useEnvSite";

function getFaviconEl() {
  return document.getElementById("favicon") || document.createElement("link");
}

function App() {
  const { env } = useEnvSite();
  const riskifiedSdk =
    env.includes("prod") || env.includes("www")
      ? "https://mfa.self-veri.com/mfa-widget-sdk.js"
      : "https://mfa-sandbox.self-veri.com/mfa-widget-sdk.js";
  const client = useClient();
  Riskified();

  useEffect(() => {
    const favicon = getFaviconEl();
    favicon.href = client.startsWith("finl")
      ? "https://www.finishline.com/favicon.ico"
      : "https://www.jdsports.com/favicon.ico";
  });

  return (
    <ErrorBoundary>
      <ThemeSelector>
        <Helmet>
          <script defer src={riskifiedSdk}></script>
        </Helmet>
        <Header />
        <Router />
      </ThemeSelector>
    </ErrorBoundary>
  );
}

export default App;
