import React from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import ValidateUser from "../components/util/ValidateUser/ValidateUser";

//Handlers
import CompleteRegistrationHandler from "./handlers/CompleteRegistrationHandler/CompleteRegistrationHandler";

//Components
import ChangePassword from "../components/ChangePassword/ChangePassword";
import Error from "../components/Error/Error";
import ForceChangePassword from "../components/ForceResetPassword/ForceResetPassword";
import Login from "../components/Login/Login";
import OTP from "../components/OTP/OTP";
import Register from "../components/Register/Register";
import FinishResetPassword from "../components/ResetPassword/FinishResetPassword";
import ResetPasswordPrompt from "../components/ResetPassword/ResetPasswordPrompt";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Router() {
  let query = useQuery();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <ValidateUser>
            <Login />
          </ValidateUser>
        </Route>
        <Route path="/register">
          <ValidateUser>
            <Register />
          </ValidateUser>
        </Route>
        <Route path="/resetPassword">
          <ValidateUser>
            <ResetPasswordPrompt />
          </ValidateUser>
        </Route>
        <Route path="/finishResetPassword">
          <FinishResetPassword />
        </Route>
        <Route path="/changePassword">
          <ChangePassword />
        </Route>
        <Route path="/completeRegistration">
          <ValidateUser>
            <CompleteRegistrationHandler token={query.get("token")} />
          </ValidateUser>
        </Route>
        <Route path="/otp">
          <ValidateUser>
            <OTP />
          </ValidateUser>
        </Route>
        <Route path="/forceResetPassword">
          <ForceChangePassword />
        </Route>
        <Route path="/error">
          <Error />
        </Route>
        <Route path="/">
          <ValidateUser>
            <Login />
          </ValidateUser>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
